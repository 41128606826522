import moment from 'moment'

/**
 * Adds Danish date localization to the moment.js library.
 */
export const addDanishDateLocalization = () => {
  moment.defineLocale('da', {
    months:
      'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split(
        '_'
      ),
    monthsShort: 'Jan_Feb_Mar_Apr_Maj_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
    weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
    weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
    weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD.MM.YYYY',
      LL: 'D. MMMM YYYY',
      LLL: 'D. MMMM YYYY HH:mm',
      LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
    },
    calendar: {
      sameDay: '[i dag kl.] LT',
      nextDay: '[i morgen kl.] LT',
      nextWeek: 'på dddd [kl.] LT',
      lastDay: '[i går kl.] LT',
      lastWeek: '[i] dddd[s kl.] LT',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'om %s',
      past: '%s siden',
      s: 'få sekunder',
      ss: '%d sekunder',
      m: 'et minut',
      mm: '%d minutter',
      h: 'en time',
      hh: '%d timer',
      d: 'en dag',
      dd: '%d dage',
      M: 'en måned',
      MM: '%d måneder',
      y: 'et år',
      yy: '%d år',
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: (n: number) => `${n}.`,
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
  })
}

/**
 * Compares two dates and returns true if the start date is earlier than or equal to the end date.
 * @param {Date | string | null} startDate - The start date to be compared.
 * @param {Date | string | null} endDate - The end date to be compared.
 * @returns {boolean} - True if the start date is earlier than or equal to the end date, false otherwise.
 */
export const isStartDateBeforeEndDate = (
  startDate: Date | string | null,
  endDate: Date | string | null
): boolean => {
  if (!startDate || !endDate) return true // No error if either date is not provided
  const start = new Date(startDate)
  const end = new Date(endDate)
  return start <= end
}

export const formatToUniversalDate = (date: string, withoutYear?: boolean) => {
  if (!date) {
    return '-'
  }

  let sanitisedString = date

  if (typeof date === 'string') {
    sanitisedString = date.replace(/[_-]/g, '.')
  }

  const sanitisedDate = moment(
    sanitisedString,
    [
      'x',
      'YYYY.MM.DD',
      'DD.MM.YYYY',
      'MM.DD.YYYY',
      'YYYY/MM/DD',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'YYYY.MM',
      'YYYY/MM',
    ],
    true // strict parsing option
  )
  const formattedDate = withoutYear
    ? moment(sanitisedDate).format('DD/MM')
    : moment(sanitisedDate).format('DD/MM/YYYY')

  if (formattedDate === 'Invalid date') {
    return '-'
  } else {
    return formattedDate
  }
}

const weekDays = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
}

export const getDateOfNextWeekDay = (weekdayIndex: string) => {
  if (!Object.prototype.hasOwnProperty.call(weekDays, weekdayIndex)) {
    return null
  }

  const now = new Date()
  now.setDate(
    now.getDate() +
      ((weekDays[weekdayIndex as keyof typeof weekDays] + (7 - now.getDay())) % 7)
  )
  return now.toISOString().split('T')[0]
}
